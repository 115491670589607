<template >
  <div>
    <!-- Header -->
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
        <h3 style="margin-top: 1%">Spoc Dashboard</h3>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
        <date-range-picker ref="picker" :opens="'left'" :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
          :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
          style="width: 240px; margin-top: 3px" :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false"
          :autoApply="false" v-model="dateRange">
        </date-range-picker>
        <!-- <v-select
          searchable
          label="gm_name"
          class="selectExample"
          placeholder="GM"
          :options="gm_names"
          v-model="selected_gm"
        >
        </v-select>
        <v-select
          clearable
          searchable
          label="spoc_name"
          class="selectExample"
          placeholder="Spoc"
          :options="spoc_names"
          v-model="selected_spoc"
        >
        </v-select> -->
        &nbsp;
        <vs-button color="dark" icon="search" @click="searchByDate">
        </vs-button>
      </vs-col>
    </vs-row>
    <div style="margin-top: 15px">
      <!-- All India Rank Summary -->
      <all-india-rank-summary :spoc="this.selected_spoc" :all_india_rank_summary="all_india_rank_summary"
        v-if="Object.keys(all_india_rank_summary).length > 1"></all-india-rank-summary>
      <!-- Overall Trends -->
      <overall-trends :overall_trends="overall_trends" v-if="overall_trends.length > 0"></overall-trends>


      <!-- Key Metrics -->
      <div v-if="
        key_metrics.length > 0 || Object.keys(visit_calls_object).length > 0
      ">
        <vs-card>
            <h3 class="card-header">KEY METRICS</h3>
          <vs-row
            vs-justify="center"
            vs-align="center"
            vs-type="flex"
            vs-w="12"
            style="margin-top:2%;"
          >
            <vs-col
              vs-justify="space-between"
              vs-align="center"
              vs-type="flex"
              vs-lg="2"
              vs-sm="6"
              vs-xs="5"
              v-for="(key_metric, index) in key_metrics"
              :key="index"
              style="margin-right:8px;"
            >
              <key-metric-card
                :key_metric="key_metric"
                @total-with-city-enrollment="getCityEnrollment"
              >
              </key-metric-card>
            </vs-col>
            <vs-col vs-justify="center" vs-align="center" vs-type="flex" vs-lg="2" vs-sm="6" vs-xs="5"
              v-for="(visit_calls, key) in visit_calls_object" :key="visit_calls.visits_key">
              <visitors-card :visits_key="key" :visits_value="visit_calls"
                @show-calls-city-summary="getCallsCitySummary"></visitors-card>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
      <br />
      <!-- Enrollments Course Wise -->
      <div>
        <vs-card v-if="course_wises.length > 0">
          <h3 class="card-header">ENROLLMENT COURSE-WISE</h3>
          <vs-row vs-justify="center" vs-type="flex" vs-w="12" style="margin-top:3%;">
            <vs-col vs-type="flex" vs-justify="space-around" vs-lg="2" vs-sm="4" vs-xs="5"
              v-for="(course_wise, index) in course_wises" :key="index">
              <course-wise-card :course_wise="course_wise" @show-city-enrollment="getCityEnrollment">
              </course-wise-card>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
      <br />
      <!-- Eligibility  -->
      <div v-if="Object.keys(eligibility_obj).length > 1">
        <vs-card>
          <h3 class="card-header">ELIGIBILITY</h3>
          <vs-row vs-justify="center" vs-type="flex" vs-w="12" style="margin-top:3%;">
            <vs-col vs-type="flex" vs-justify="space-around" vs-lg="2" vs-sm="4" vs-xs="5"
              v-for="(eligibility, key, index) in eligibility_obj" :key="index">
              <eligibility-card :eligibility_key="key" :eligibility_value="eligibility"
                @show-eligibility-city-summary="getEligibilityCitySummary"></eligibility-card>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
      <br />
      <!-- Referrals  -->
      <div v-if="Object.keys(referrals_obj).length > 1">
        <vs-card>
          <h3 class="card-header">REFERRALS</h3>
          <vs-row vs-justify="center" vs-type="flex" vs-w="12" style="margin-top:3%;">
            <vs-col vs-type="flex" vs-justify="space-around" vs-lg="2" vs-sm="4" vs-xs="5"
              v-for="(referral, key, index) in referrals_obj" :key="index">
              <referrals-card :referral_key="key" :referral_value="referral"
                @show-referrals-city-summary="getReferralsCitySummary"></referrals-card>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
       <!-- Roadmap Webinar -->
       <div v-if=" Object.keys(road_map_overall_counts).length > 1">
        <vs-card>
          <h3 class="card-header">ROADMAP WEBINAR</h3>
          <vs-row vs-justify="center" vs-type="flex" vs-w="12" style="margin-top: 3%">
            <vs-col vs-type="flex" vs-justify="space-around" vs-lg="2" vs-sm="4" vs-xs="5"
              v-for="(road_map_overall_count, key, index) in road_map_overall_counts" :key="index">
              <roadmap-webinar :road_map_key="key" :road_map_value="road_map_overall_count"
                @show-road-map-type="getRoadmapCitySummary"></roadmap-webinar>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
     <!--Net Enquiry -->
      <div v-if="overall_sources.length > 1">
        <vs-card>
          <div class="card-header">
            <vs-row  vs-w="12">
              <vs-col  vs-w="3" >
                <h3 class="card-header">NET ENQUIRIES <span v-if='selected_source !== "" && selected_source !== null'> : {{ selected_source }}</span></h3>
              </vs-col>
              <vs-col vs-offset="7" vs-w="2" >
                <v-select
                  clearable
                  searchable
                  class="selectSource"
                  placeholder="Source"
                  :options="overall_sources"
                  v-model="selected_source"
                >
                </v-select>
              </vs-col>
            </vs-row>
          </div>
          <vs-row vs-justify="center" vs-type="flex" vs-w="12" v-if='selected_source == "" || selected_source == null'>
            <h4 style="margin:1%">Please Select Source</h4>
          </vs-row>
          <vs-row vs-justify="center" vs-type="flex" vs-w="12" style="margin-top: 3%">
            <vs-col
              vs-w="2"
              v-for="(net_enquiry, key, index) in net_enquirys[0]"
              :key="index"
            >
              <net-enquiry-card
                :net_enquiry="net_enquiry" :net_enquiry_key="key" :source="selected_source"
                @total-with-city-netenquiry="getNetEnquirysCitySummary"
              >
              </net-enquiry-card>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
      <br />
      <!-- Overall Leads -->
      <div v-if="overall_leads.length > 1">
        <vs-card>
          <div slot="header">
            <h3>OVERALL LEADS</h3>
          </div>
          <overall-leads
            :overall_leads="overall_leads"
            @total-count-overallleads="getOverallLeadsSummary"
          >
          </overall-leads>
        </vs-card>
      </div>
      <br />
      <!-- Leads Summary-->
      <leads-summary
        :leads_summary="leads_summary"
        v-if="leads_summary.length > 0"
      ></leads-summary>

      <!-- Enrollments Popup -->
      <enrollments-popup :enrollments_popup="enrollments_popup" :city_wises_enrollments="city_wises_enrollments"
        :date_props="date_props" @close-enrollments-popup="enrollments_popup = false; this.selected_course = ''">
      </enrollments-popup>

      <!-- Connected Call Popup -->
      <ConnectedCallsPopup :calls_city_summary_popup="calls_city_summary_popup" :calls_city_wises="calls_city_wises"
        :date_props="date_props" @close-connected-call-popup="calls_city_summary_popup = false"></ConnectedCallsPopup>
      <!-- Visit Count Popup -->
      <visit-count-popup :visits_city_summary_popup="visits_city_summary_popup" :visits_city_wises="visits_city_wises"
        :date_props="date_props" @close-visit-count-popup="visits_city_summary_popup = false"></visit-count-popup>
      <!-- Eligibility Popup -->
      <eligibility-enrollments-popup :eligibility_city_wises="eligibility_city_wises"
        :eligibility_city_summary_popup="eligibility_city_summary_popup" :date_props="date_props"
        @close-eligibility-popup="eligibility_city_summary_popup = false">
      </eligibility-enrollments-popup>
      <!-- Referral city wise popup -->
      <referral-city-wise-popup :referrals_city_wises="referrals_city_wises"
        :referral_city_summary_popup="referral_city_summary_popup" :date_props="date_props"
        @close-referral-city-popup="referral_city_summary_popup = false">
      </referral-city-wise-popup>
      <!-- Road Map Webinar City -->
      <roadmap-webinar-city-wise-popup :roadmap_city_summary_popup="roadmap_city_summary_popup"
      :roadmap_city_wises="roadmap_city_wises"
        @close-roadmap-city-popup="roadmap_city_summary_popup = false" :date_props="date_props"></roadmap-webinar-city-wise-popup>
      
      <!-- net enquiry city wise popup -->
      <net-enquiry-popup :net_enquiry_summary_popup="net_enquiry_summary_popup"
        :netenquiry_city_wises="netenquiry_city_wises" :date_props="date_props"
        @close-net-enquiry-popup="net_enquiry_summary_popup = false">
      </net-enquiry-popup>

      <!-- overall leads city wise -->
      <overall-leads-popup
        :overall_leads_summary_popup="overall_leads_summary_popup"
        :overallleads_city_wises="overallleads_city_wises"
        :date_props="date_props"
        @close-overall_leads-popup="overall_leads_summary_popup = false"
      ></overall-leads-popup>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ApiServices from "../../ApiServices";
import constants from "../../../constants.json";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import KeyMetricCard from "../../components/CmDashboard/keyMetricCard.vue";
import CourseWiseCard from "../../components/CmDashboard/CourseWiseCard.vue";
import CityWiseCard from "../../components/CmDashboard/CityWiseCard.vue";
import EligibilityCard from "../../components/CmDashboard/EligibilityCard.vue";
import ReferralsCard from "../../components/CmDashboard/ReferralsCard.vue";
import VisitorsCard from "../../components/CmDashboard/VisitorsCard.vue";
import ConectedCalls from "../../components/CmDashboard/ConectedCalls.vue";
import VisitCalls from "../../components/CmDashboard/VisitCalls.vue";
import EligibilityCityWise from "../../components/CmDashboard/EligibilityCityWise.vue";
import ReferralsCityWise from "../../components/CmDashboard/ReferralsCityWise.vue";
import OverallTrends from "../../components/CmDashboard/OverallTrends.vue";
import AllIndiaRankSummary from "../../components/CmDashboard/AllIndiaRankSummary.vue";
import EnrollmentsPopup from "../../components/CmDashboard/EnrollmentsPopup.vue";
import ConnectedCallsPopup from "../../components/CmDashboard/ConnectedCallsPopup.vue";
import VisitCountPopup from "../../components/CmDashboard/VisitCountPopup.vue";
import EligibilityEnrollmentsPopup from "../../components/CmDashboard/EligibilityEnrollmentsPopup.vue";
import ReferralCityWisePopup from "../../components/CmDashboard/ReferralCityWisePopup.vue";
import RoadmapWebinarCityWisePopup from "../../components/CmDashboard/RoadmapWebinarCityWisePopup.vue"
import NetEnquiryCard from "../../components/CmDashboard/NetEnquiryCard.vue";
import NetEnquiryPopup from "../../components/CmDashboard/NetEnquiryPopup.vue";
import LeadsSummary from "../../components/CmDashboard/LeadsSummary.vue";
import OverallLeads from "../../components/CmDashboard/OverallLeads.vue";
import RoadmapWebinar from "../../components/CmDashboard/RoadmapWebinarCard.vue";
import OverallLeadsPopup from "../../components/CmDashboard/OverallLeadsPopup.vue";

export default {
  components: {
    KeyMetricCard,
    CourseWiseCard,
    "v-select": vSelect,
    CityWiseCard,
    DateRangePicker,
    EligibilityCard,
    ReferralsCard,
    VisitorsCard,
    ConectedCalls,
    VisitCalls,
    EligibilityCityWise,
    ReferralsCityWise,
    EnrollmentsPopup,
    OverallTrends,
    AllIndiaRankSummary,
    ConnectedCallsPopup,
    VisitCountPopup,
    EligibilityEnrollmentsPopup,
    RoadmapWebinarCityWisePopup,
    ReferralCityWisePopup,
    NetEnquiryCard,
    NetEnquiryPopup,
    LeadsSummary,
    OverallLeads,
    OverallLeadsPopup,
    RoadmapWebinar,
  },
  mounted() {
    this.getOverallTrendsData();
    this.getEnrollmentData();
    this.getVisitsData();
    this.getRoadmapOverallCount();
    this.getEligibilityData();
    this.getReferralsData();
    this.getNetEnquiryData("Google");
    this.getOverallLeadsCard();
    this.getOverallLeads();
    this.getAllGmWithSpoc();
    this.getAllIndiaRankSummary();
    this.gm_names = [];
  },
  data() {
    return {
      date_props: [],
      open: false,
      dateRange: {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ),
        endDate: new Date(),
      },
      overall_leads: [],
      all_gm_with_spoc: [],
      net_enquirys: [],
      netenquiry_city_wises: [],
      key_metrics: [],
      course_wises: [],
      city_wises_enrollments: [],
      roadmap_city_wises:[],
      overall_sources:[],
      selected_source: "Google",
      selected_course: "",
      selected_spoc: "",
      selected_gm: "",
      courses: [
        { text: "CPA" },
        { text: "CMA" },
        { text: "CFA" },
        { text: "FRM" },
      ],
      net_inquiry_metrics: [
        { text: "Overall Net Inquiry Enrollment", value: "40" },
        { text: "Enrollment % from Net Inquiries", value: "66.67%" },
        { text: "New Net Inquiries", value: "300" },
        { text: "New Inquiry Enrollment", value: "25" },
        { text: "Conversion % (New Net Inquiries)", value: "8.33%" },
      ],
      mhp_metrics: [
        { text: "Overall MHP Enrollment", value: "40" },
        { text: "Enrollment % from MHP", value: "66.67%" },
        { text: "New MHP", value: "300" },
        { text: "New MHP Enrollment", value: "25" },
        { text: "Conversion % (New MHP)", value: "8.33%" },
      ],
      f2f_class: [
        { text: "Demo Registrations", value: "10" },
        { text: "Demo Attended", value: "6" },
        { text: "% Attendance", value: "40%" },
        { text: "Demo Enrolled", value: "2" },
        { text: "% Enrollment", value: "50%" },
        { text: "Not connected since Class Date", value: "1" },
        { text: "Delays since class date", value: "1" },
      ],
      roadmap_webinar: [
        { text: "Webinar Registrations", value: "10" },
        { text: "Webinar Attended", value: "6" },
        { text: "% Attendance", value: "40%" },
        { text: "Webinar Enrolled", value: "2" },
        { text: "% Enrollment", value: "50%" },
        { text: "Not connected since Class Date", value: "1" },
        { text: "Delays", value: "1" },
      ],
      referrals: [
        { text: "Overall Referral Enrollment", value: "12" },
        { text: "Enrollment % from Referrals", value: "4.00%" },
        { text: "New Referrals(selected period)", value: "7" },
        { text: "New Referral Enrollment", value: "3" },
        { text: "Conversion % (New Referrals)", value: "42.86%" },
      ],
      spoc_names: [],
      gm_names: [],
      road_map_overall_counts:{},
      gm_selecter: true,
      visit_calls_object: {},
      selected_city:'',
      calls_city_wises: [],
      eligibility_city_wises: [],
      overallleads_city_wises: [],
      referrals_city_wises: [],
      visits_city_wises: [],
      eligibility_obj: {},
      referrals_obj: {},
      overall_leads_summary_popup: false,
      net_enquiry_summary_popup: false,
      eligibility_city_summary_popup: false,
      roadmap_city_summary_popup: false,
      referral_city_summary_popup: false,
      calls_city_summary_popup: false,
      visits_city_summary_popup: false,
      enrollments_popup: false,
      overall_trends: [],
      all_india_rank_summary: {},
      gm_spocs: {},
      eligibility_city_popup: false,
      visits_city_popup: false,
      leads_summary: [],
    };
  },
  watch: {
    selected_source(selected_source){
      if (selected_source !== "" && selected_source !== null) {
        this.getNetEnquiryData(selected_source);
        return " : " + selected_source
      }else{
        this.net_enquirys = []
      }
    },
    selected_spoc(selected_spoc) {
      if (selected_spoc == "" || selected_spoc == null) {
        this.selected_spoc = "";
        this.all_india_rank_summary = {};
      }
      console.log("selected_spoc spoc name", this.selected_spoc);
    },
    selected_gm: function (gm) {
      console.log("selected gm", gm);

      this.spoc_name = [];
      if (gm == "" || gm == null) {
        this.selected_spoc = "";
        this.selected_gm = "";
      }
      this.spoc_names = this.gm_spocs[gm];
      console.log("spoc name", this.spoc_names);
    },
    calls_city_summary_popup: function (val) {
      console.log("calls_city_summary_popup---", val);
      console.log(
        "calls_city_summary_popup enrollmenrt---",
        this.enrollments_popup
      );
    },
    enrollments_popup: function (val) {
      console.log("enrollments_popup---", val);
      console.log(
        "enrollments_popup calls_city_summary_popup---",
        this.calls_city_summary_popup
      );
    },
  },
  methods: {
    getOverallLeadsCard() {
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
      };
      this.overall_leads = [];
      ApiServices.getOverallLeadsCard(obj)
        .then((response) => {
          console.log("getOverallLeads", response.data);
          this.overall_leads = response.data;

          // this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    getOverallLeadsSummary(source) {
      this.calls_city_wises = [];
      this.visits_city_wises = [];
      this.eligibility_city_wises = [];
      this.referrals_city_wises = [];
      this.city_wises_enrollments = [];
      this.netenquiry_city_wises = [];
      this.overallleads_city_wises = [];
      // this.$vs.loading();
      let obj = {
        source: source.level,
        spoc: this.selected_spoc,
        gm: this.selected_gm,
        // spoc: localStorage.getItem("spoc_name"),
      };
      this.date_props = {
        level: source.level.toUpperCase(),
        value: source.value,
        spoc: this.selected_spoc,
        gm: this.selected_gm,
      };
      this.$vs.loading();
      ApiServices.getLeadsCitySummary(obj)
        .then((response) => {
          console.log("Dataaa", response.data);
          this.overallleads_city_wises = response.data;
          this.overall_leads_summary_popup = true;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getNetEnquiryData(source) {
      // this.course_wises = [];
      this.net_enquirys = [];
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      let obj = { spoc: this.selected_spoc, gm: this.selected_gm, };
      ApiServices.getNetEnquiryData(startDate, endDate, obj).then((response) => {
          let enrollment = response.data;
          let check_if_array = Array.isArray(enrollment);
          if (check_if_array) {
            this.overall_sources = []
            for (let i = 0; i < enrollment.length; i++) {
              this.overall_sources.push(enrollment[i].source);
              if (enrollment[i].source  == source) {
                var element = enrollment[i]
                delete element["source"]
                this.net_enquirys.push(element)
              }
            }
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getRoadmapOverallCount(){
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      ApiServices.getRoadmapOverallCounts(startDate, endDate,this.selected_city,this.selected_spoc,this.selected_gm)
          .then((response) => {
            let data = response.data;
          let check_if_array = Array.isArray(data);
          if (check_if_array) {
            for (let i = 0; i < data.length; i++) {
              const element = data[0];
              this.road_map_overall_counts = element;
            }
          }
            this.$vs.loading.close();
            
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      },
    getRoadmapCitySummary(val) {
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      this.date_props = {
        startDate: startDate,
        endDate: endDate,
        gm: this.selected_gm,
        spoc: this.selected_spoc,
      };
      if (val == "webinar_registrations") {
        // getRoadmapCitySummary
        ApiServices.getRoadmapCitySummary(startDate, endDate,this.selected_city,this.selected_spoc,this.selected_gm)
          .then((response) => {
            console.log("getReferralsCitySummary", response.data);
            this.roadmap_city_wises = response.data;
            this.roadmap_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
        
      }

    },
    getEnrollmentData() {
      this.course_wises = [];
      this.key_metrics = [];
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
      };

      console.log("start_date", startDate);
      console.log("end_date", endDate);
      console.log("selected_spoc", this.selected_spoc);
      console.log("selected_gm", this.selected_gm);

      // let url = `${constants.MILES_DASHBOARDS}getOverallEnrollmentsCount?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
      // // let url = `http://milesapi.bulbot.com/api/performance-dashboard/enrollments-overall-count/?end-date=${endDate}&start-date=${startDate}`;

      // axios

      //   .get(url, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.userAccessToken}`,
      //     },
      //   })
      ApiServices.getEnrollmentData(startDate, endDate, obj)
        .then((response) => {
          let enrollment = response.data;
          console.log("enrollment", enrollment);
          let check_if_array = Array.isArray(enrollment);
          if (check_if_array) {
            for (let i = 0; i < enrollment.length; i++) {
              const element = enrollment[i];
              if (element.course == "Total enrollments") {
                this.key_metrics.push(element);
              } else if (element.course != "Total enrollments") {
                this.course_wises.push(element);
              } else {
                this.key_metrics = [];
                this.course_wises = [];
              }
            }
          }

          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getCityEnrollment(course) {
      console.log("course--", course);
      this.calls_city_wises = [];
      this.visits_city_wises = [];
      this.eligibility_city_wises = [];
      this.referrals_city_wises = [];
      this.netenquiry_city_wises = [];
      if (course == null) {
        this.selected_course = "";
      } else {
        this.selected_course = course;
      }
      console.log("course2--", course);
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
        course: this.selected_course,
      };
      if (this.selected_course != "") {
        this.date_props = {
          card_header:
            startDate + " - " + endDate + " | " + this.selected_course,
          gm: this.selected_gm,
          spoc: this.selected_spoc,
          startDate: startDate,
          endDate: endDate,
          course: this.selected_course
        };
        console.log("this.date_props course not empty", this.date_props)
      } else {
        this.date_props = {
          card_header: startDate + " - " + endDate,
          gm: this.selected_gm,
          spoc: this.selected_spoc,
          startDate: startDate,
          endDate: endDate,
          course: "",
        };
        console.log("this.date_props course empty", this.date_props);
      }
      ApiServices.getCityEnrollment(startDate, endDate, obj)
        .then((response) => {
          console.log("Find Table Data", response.data);
          this.city_wises_enrollments = response.data;
          this.enrollments_popup = true;

          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getAllGmWithSpoc() {
      this.gm_names = [];
      let url = `${constants.MILES_DASHBOARDS}gmSpoc`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("gmSpoc", response);
          this.gm_spocs = response.data;
          this.gm_names = Object.keys(this.gm_spocs);
          console.log("gm_names--", this.gm_names);
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    searchByDate() {
      this.all_india_rank_summary = {};
      this.overall_sources = [];
      this.leads_summary = [];
      this.overall_trends = [];
      this.overall_leads = [];
      this.key_metrics = [];
      this.course_wises = [];
      this.city_wises_enrollments = [];
      this.visit_calls_object = {};
      this.eligibility_obj = {};
      this.referrals_obj = {};
      this.calls_city_wises = [];
      this.visits_city_wises = [];
      this.road_map_overall_counts =[];
      this.eligibility_city_wises = [];
      this.referrals_city_wises = [];
      this.netenquiry_city_wises = [];
      this.getEnrollmentData();
      this.getVisitsData();
      this.getEligibilityData();
      this.getRoadmapOverallCount();
      this.getReferralsData();
      this.getNetEnquiryData("Google");
      this.getOverallTrendsData();
      this.getAllIndiaRankSummary();
      this.getOverallLeads();
      this.getOverallLeadsCard()
    },
    getVisitsData() {
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
      };

      console.log("start_date", startDate);
      console.log("end_date", endDate);
      console.log("selected_spoc", this.selected_spoc);
      console.log("selected_gm", this.selected_gm);

      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/overall-visits-calls/?end-date=${endDate}&start-date=${startDate}`;
      // let url = `${constants.MILES_DASHBOARDS}getOverallVisitsCalls?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;

      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.userAccessToken}`,
      //     },
      //   })
      ApiServices.getVisitsData(startDate, endDate, obj)
        .then((response) => {
          let visits = response.data;
          console.log("visits", visits);
          let check_if_array = Array.isArray(visits);
          let visit_call = {};
          if (check_if_array) {
            for (let i = 0; i < visits.length; i++) {
              visit_call = visits[0];
              // this.key_metrics.push(element);
              console.log("key metrics object", visit_call);
            }
            this.visit_calls_object = {
              connected_calls: {
                count: visit_call["connected_calls"],
                utilization: visit_call["call_utilization"],
              },
              visit_count: {
                count: visit_call["visit_count"],
                utilization: visit_call["visit_utilization"],
              },
            };
            console.log("visit_calls_object", this.visit_calls_object);
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getCallsCitySummary(val) {
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
      };
      this.date_props = {
        startDate: startDate,
        endDate: endDate,
        gm: this.selected_gm,
        spoc: this.selected_spoc,
      };
      if (val == "connected_calls") {
        this.city_wises_enrollments = [];
        this.visits_city_wises = [];
        this.eligibility_city_wises = [];
        this.referrals_city_wises = [];
        // let url = `http://milesapi.bulbot.com/api/performance-dashboard/calls-city-summary/?end-date=${endDate}&start-date=${startDate}`;
        // let url = `${constants.MILES_DASHBOARDS}getCallsCitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm_name}`;

        // axios
        //   .get(url, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.userAccessToken}`,
        //     },
        //   })
        ApiServices.getCallsCitySummary(startDate, endDate, obj)
          .then((response) => {
            console.log("Data", response.data);
            this.calls_city_wises = response.data;
            this.calls_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
      if (val == "visit_count") {
        this.city_wises_enrollments = [];
        this.calls_city_wises = [];
        this.eligibility_city_wises = [];
        this.referrals_city_wises = [];
        this.netenquiry_city_wises = [];
        // let url = `http://milesapi.bulbot.com/api/performance-dashboard/visits-city-summary/?end-date=${endDate}&start-date=${startDate}`;
        // let url = `${constants.MILES_DASHBOARDS}getVisitsCitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;

        // axios
        //   .get(url, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.userAccessToken}`,
        //     },
        //   })
        ApiServices.getVisitsCitySummary(startDate, endDate, obj)
          .then((response) => {
            console.log("Data", response.data);
            this.visits_city_wises = response.data;
            this.visits_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
    },
    getEligibilityData() {
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
      };
      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/overall-eligibility-summary/?end-date=${endDate}&start-date=${startDate}`;
      // let url = `${constants.MILES_DASHBOARDS}getOverallEligibilitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;

      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.userAccessToken}`,
      //     },
      //   })
      ApiServices.getEligibilityData(startDate, endDate, obj)
        .then((response) => {
          let elilibility = response.data;
          console.log("elilibility", elilibility);
          let check_if_array = Array.isArray(elilibility);
          if (check_if_array) {
            for (let i = 0; i < elilibility.length; i++) {
              const element = elilibility[0];
              this.eligibility_obj = element;
              console.log("key eligibility object", this.elilibility_obj);
            }
          }

          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getEligibilityCitySummary(val) {
      this.referrals_city_wises = [];
      this.calls_city_wises = [];
      this.visits_city_wises = [];
      this.eligibility_city_wises = [];
      this.city_wises_enrollments = [];
      this.netenquiry_city_wises = [];
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
      };
      this.date_props = {
        startDate: startDate,
        endDate: endDate,
        gm: this.selected_gm,
        spoc: this.selected_spoc,
      };
      if (val == "overall_eligibility_enrollments") {
        this.$vs.loading();
        // let url = `http://milesapi.bulbot.com/api/performance-dashboard/eligibility-city-summary/?end-date=${endDate}&start-date=${startDate}`;
        // let url = `${constants.MILES_DASHBOARDS}getEligibilityCitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;

        // axios
        //   .get(url, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.userAccessToken}`,
        //     },
        //   })
        ApiServices.getEligibilityCitySummary(startDate, endDate, obj)
          .then((response) => {
            this.eligibility_city_wises = response.data;
            this.eligibility_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
    },
    getReferralsData() {
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
      };
      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/overall-referral-summary/?end-date=${endDate}&start-date=${startDate}`;
      // let url = `${constants.MILES_DASHBOARDS}getOverallReferralSummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;

      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.userAccessToken}`,
      //     },
      //   })
      ApiServices.getReferralsData(startDate, endDate, obj)
        .then((response) => {
          let referrals = response.data;
          console.log("referrals", referrals);
          let check_if_array = Array.isArray(referrals);
          if (check_if_array) {
            for (let i = 0; i < referrals.length; i++) {
              const element = referrals[0];
              this.referrals_obj = element;
              console.log("key referrals object", this.referrals_obj);
            }
          }

          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getNetEnquirysCitySummary(val,key) {
      if (key !== null && key !== "") {
        this.calls_city_wises = [];
        this.visits_city_wises = [];
        this.eligibility_city_wises = [];
        this.referrals_city_wises = [];
        this.city_wises_enrollments = [];
        this.netenquiry_city_wises = [];
        this.$vs.loading();
        let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let obj = {
          source: val,
          gm: this.selected_gm,
          spoc: this.selected_spoc,
        };
        this.date_props = {
          startDate: startDate,
          endDate: endDate,
          source: val,
          "key": key,
          gm: this.selected_gm,
          spoc: this.selected_spoc,
        };
        this.$vs.loading();
        ApiServices.getNetEnquirysCitySummary(startDate, endDate, obj)
          .then((response) => {
            for (let i = 0; i < response.data.length; i++) {
                this.netenquiry_city_wises.push({"city":response.data[i].city,"enquiry_count":response.data[i][key]})
            }
            this.net_enquiry_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
    },
    getReferralsCitySummary(val) {
      this.calls_city_wises = [];
      this.visits_city_wises = [];
      this.eligibility_city_wises = [];
      this.referrals_city_wises = [];
      this.city_wises_enrollments = [];
      this.netenquiry_city_wises = [];
      // this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
      };
      this.date_props = {
        startDate: startDate,
        endDate: endDate,
        gm: this.selected_gm,
        spoc: this.selected_spoc,
      };
      if (val == "overall_referral_enrollments") {
        this.$vs.loading();

        // let url = `http://milesapi.bulbot.com/api/performance-dashboard/referral-city-summary/?end-date=${endDate}&start-date=${startDate}`;
        // let url = `${constants.MILES_DASHBOARDS}getReferralCitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
        // axios
        //   .get(url, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.userAccessToken}`,
        //     },
        //   })
        ApiServices.getReferralsCitySummary(startDate, endDate, obj)
          .then((response) => {
            console.log("Dataaa", response.data);
            this.referrals_city_wises = response.data;
            this.referral_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
    },
    getOverallTrendsData() {
      console.log("gm", this.selected_gm);
      console.log("cm", this.selected_spoc);

      this.$vs.loading();

      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/overall-trend-summary/`;
      // if (this.selected_gm != null && this.selected_gm != "") {
      //   url += `?gm=${this.selected_gm}`;
      // } else if (this.selected_spoc != null && this.selected_spoc != "") {
      //   url += `?spoc=${this.selected_spoc}`;
      // }
      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Token 0d03c8c040170a8a621e1a0edbe73ad23bf158e2`,
      //     },
      //   })
      ApiServices.getOverallTrendsSummary(this.selected_gm, this.selected_spoc)
        .then((response) => {
          console.log("getOverallTrendsSummary", response.data);
          this.overall_trends = response.data;

          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    getAllIndiaRankSummary() {
      console.log("gm--", this.selected_gm);
      console.log("cm--", this.selected_spoc);
      this.$vs.loading();

      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );

      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/spoc-all-india-rank-summary/?end-date=${endDate}&spoc=${this.selected_spoc}&start-date=${startDate}`;
      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Token 0d03c8c040170a8a621e1a0edbe73ad23bf158e2`,
      //     },
      //   })
      ApiServices.getAllIndiaRankSummary(startDate, endDate, this.selected_spoc)

        .then((response) => {
          console.log("spoc-all-india-rank-summary", response.data);
          let summary = response.data;
          if (summary.length > 0) {
            this.all_india_rank_summary = summary[0];
          }

          console.log(
            "spoc-all-india-rank-summary--",
            this.all_india_rank_summary
          );
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    getOverallLeads() {
      this.$vs.loading();

      ApiServices.getOverallLeads()
        .then((response) => {
          console.log("getOverallLeads", response.data);

          this.leads_summary = response.data

          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
        });
    }
  },
};
</script>
<style scoped>

.selectExample {
  width: 300px;
  margin-top: 2px;
}
/* .card-header {
  background: #00008B;
  padding: 10px;
  margin: -10px;
  border-radius: 15px 15px 0px 0px;
  color: white;
} */

.card-header {
  text-align:start;
  background: #0044BA;
  padding: 10px;
  margin: -10px;
  border-radius: 15px 15px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}
.selectSource{
 width: 100%;
 margin-top: 0px;
 background-color: white;
 color:black !important;

}

.vs__dropdown-menu{
  color: black;
}

/* .con-vs-popup .vs-popup{
      width: 70%;
} */
</style>
